*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    font-size: 16px;
    --color-bg: #4f00ff;
    --color-text: #000000;
    --color-text-alt: #1D0460;
    --color-text-slide: #53dbf1;
    --color-link: #000;
    --color-link-hover: #f33ba5;
    --page-padding: 25px;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    color: #000000;
    background-color: var(--color-bg);
    font-family: termina, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    will-change: #53dbf1;
    overflow-y: auto;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 3000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}


a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.vector {
	position: fixed;
	pointer-events: none;
	fill: none;
	stroke: #000;
	stroke-width: 0.5px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
}
/* #logo{
	visibility: collapse !important;
} */
.vector--circle {
	width: 44%;
	left: 28%;
	top: -50%;
	z-index: -1;
}

.intro {
	padding: var(--page-padding);
}

.intro__title {
	margin: 0 0 2rem;
	font-size: 1rem;
	font-weight: 600;
}

.intro__description {
	line-height: 1.2;
	color: var(--color-text-alt);
}

.menu {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: baseline;
	padding: var(--page-padding);
}

.menu__item {
	text-decoration: none;
	margin: 0.5rem;
	color: var(--color-text);
	transition: color 0.3s;
}

.menu__item:hover {
	color: var(--color-link-hover);
}

.menu__item i {
	font-size: 1.5rem;
}





body.frame {
	margin: 0;
	overflow: auto;
	/* Permite el desplazamiento en el body */
}
.container_home{
		visibility: visible;
		display: block;
		width: 100%;
		height: 100%;
		margin-top: 50px;
}
main {
	height: auto;
	/* Altura completa de la ventana */
	position: fixed;
	/* Fija la sección principal en la parte superior */
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	/* Asegura que esté por encima del contenido desplazable */
	overflow: visible;
}
section {
	height: 100vh;
	display: list-item;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

section.work-experience {
	height: 50vh;
}



/* Ajustes responsivos para dispositivos móviles */
@media screen and (max-width: 52.99em) {
	main {
		position: relative;
		/* Cambia a posición relativa en móviles */
		height: auto;
	}

	.scrollable-content {
		margin-top: 0;
		/* Elimina el margen superior en móviles */
	}

	section {
		min-height: auto;
		/* Permite que las secciones se ajusten a su contenido en móviles */
	}
}

.author {
	grid-area: 2 ​/ 1 / 4 / 2;
	align-self: center;
	padding-left: var(--page-padding);
	font-size: .6rem;
	color: #000000;
	--color-link: #000000;
	--color-link-hover: #000000;
}

.title {
	line-height: 1;
	font-size: clamp(1.75rem, 3vw, 15rem);
	white-space: nowrap;
	margin: 0;
	padding: var(--page-padding);
	justify-self: center;
	text-align: center;
}

.showreel {
	display: flex;
	align-items: center;
	grid-area: 4 / 3 / 5 / 4;
	align-self: center;
	justify-self: end;
	padding: var(--page-padding);
	text-decoration: none;
	color: var(--color-link-hover);
}

.container_home{
	width: 100%;
	height: auto;

}
.showreel::before {
	content: '\00B7';
	font-size: 5rem;
	line-height: 0;
	margin: 0 0.25rem 0.25rem 0;
}

.showreel:hover::before {
	animation: pulse 0.8s infinite alternate linear;
}

@keyframes pulse {
	to {
		transform: scale3d(1.5, 1.5, 1);
	}
}

.slideshow {
	height: 100vw;
	position: relative;
	display: grid;
	grid-template-columns: 60px minmax(200px, 70%) 60px;
	grid-template-areas: '... img ...'
		'nav-prev img nav-next'
		'... img ...'
		'title title title';
	justify-content: center;
}

.meta {
	grid-area: title;
	width: max-content;
	text-align: center;
	justify-self: center;
	margin: 1rem 0 0 0;
}

.no-js .meta {
	display: none;
}

.meta__heading {
	font-weight: 600;
	font-size: 0.75rem;
	display: block;
	margin: 0 0 0 0;
}

.meta__content {
	color: var(--color-text-slide);
	display: grid;
}

.meta__content-title {
	opacity: 0;
	grid-area: 1 / 1 / 2 / 2;
	will-change: opacity, transform;
}

.container {
	width: 100%;
	max-width: 800px;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: stretch;
	gap: 1.25rem;
	transition: all 400ms;
  }


  /* GALERIA  */

  

/* .gallery {
	grid-area: img;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	margin-top: auto;
	border-radius: 35vw 35vw 0 0;
	justify-self: center;
}



.gallery__img {
	overflow: hidden;
	position: absolute;
	opacity: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
	will-change: opacity, transform;
}

.gallery__video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}



.no-js .gallery__img:first-child {
	opacity: 1;
}

.gallery__img-inner {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	will-change: filter, transform;
} */



.slides__img--current {
	pointer-events: auto;
	opacity: 1;
	z-index: 1000;
}

/* Sección de lugares de trabajo */
.work-experience {
    padding: var(--page-padding);
    margin-top: 2rem;
    color: var(--color-text);
}

.work-experience__title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--color-text);
	text-align: center;
}

.work-experience__years {
    font-weight: normal;
}

.timeline {
    flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  display: flex;
  position: unset;
  width: 100%;
  text-align: center;
}




.timeline__item {
	position: relative;
  z-index: 1;
  /* cursor: pointer; */
  margin: 0.5rem;
  background-image: url(../img/soporte_years.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 180px;
  color: #000000;
}

.timeline__year {
    
    padding: .8rem 3rem;
    
   
    border-radius: 90px;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
    color: var(--color-text);
}
.timeline__year::before {
	content: attr(data-year);
	display: block;
	font-weight: bold;
  }
  
  .timeline__year::after {
	content: attr(data-info);
	display: block;
	font-size: 0.7rem;
	color: var(--color-text-alt);
	margin-top: 0.25rem;
  }

.timeline__item--active .timeline__year,
.timeline__item:hover .timeline__year {
    
    color: var(--color-bg);
}
#spline-container {
    width: 100%;
    height: 100vh;
    max-height: 1080px;
    position: relative;
    overflow: hidden;
}

#spline-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Pantallas extra pequeñas (teléfonos, menos de 576px) */
@media (max-width: 575.98px) {
    #spline-container {
		max-height: 500px;
		
    }
	#spline-canvas {
		width: 100% !important;
		height: 100% !important;
	}
	#post {
		width: 80% !important; 
	}
}

/* Pantallas pequeñas (tabletas pequeñas, 576px y más) */
@media (min-width: 576px) and (max-width: 767.98px) {
    #spline-container {
        max-height: 856px;
    }
	#spline-canvas {
		width: 100% !important;
		height: 100% !important;
	}
}

/* Pantallas medianas (tabletas, 768px y más) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #spline-container {
        max-height: 400px;
    }
}

/* Pantallas grandes (desktops, 992px y más) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #spline-container {
        max-height: 550px;
    }
}

/* Pantallas extra grandes (desktops grandes, 1200px y más) */
@media (min-width: 1200px) {
    #spline-container {
        max-height: 650px;
    }
}
/* Pantallas extra grandes (desktops grandes, 1200px y más) */
@media (min-width: 1400px) {
    #spline-container {
        max-height: 800px;
    }
}
/* Sección de trabajos realizados */
:root {
    --card_width: 250px;
    --card_border_radius: 120px;
    /* --row_increment: 10px; */
    --card_small: 10;
    --card_medium: 15;
    --card_large: 20;
}

.portfolio-section {
    padding: 2rem 8rem;
}

.portfolio-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 20px;
}

.pin_container {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--card_width), 1fr));
    grid-auto-rows: var(--row_increment);
    justify-content: center;
    gap: 20px;
}

/* Asegúrate de que los contenedores padres también tengan overflow visible */
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.card {
    position: relative;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    cursor: pointer;
    text-align: center;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
    transition: transform 0.5s;
}
  
  .card:hover img {
	transform: rotateX(10deg) rotateY(-10deg) translateZ(50px);
  }
  
  .card .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #4f00ffc9;
	opacity: 0;
	transition: opacity 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
  }
  
  .card:hover .overlay {
	opacity: 1;
  }
  
  .card .overlay h3 {
	font-size: 24px;
	margin-bottom: 10px;
	color: #00ffe9;
  }
  
  .card .overlay p {
	font-size: 18px;
	margin: 5px 0;
	color: #00ffe9;
  }

  /* Estilos existentes */
  .popup {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
    align-items: center;
    justify-content: center;
}

.popup:not([style*="display: flex"]) {
    pointer-events: none;
}

.popup-content {
    position: relative;
    padding: 20px;
    background-color: var(--color-bg);
    text-align: center;
    border-radius: 15px;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popup-content img {
    max-width: 100%;
    max-height: calc(90vh - 80px);
    object-fit: contain;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 30px;
    height: 30px;
    background-color: #53dbf1;
    color: #000000;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.close:hover {
    background-color: #3a00c2;
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .popup-content {
        padding: 15px;
    }

    .popup-content img {
        max-height: calc(90vh - 60px);
    }

    .close {
        top: 5px;
        right: 10px;
        width: 25px;
        height: 25px;
        font-size: 16px;
        line-height: 25px;
    }
}


@media screen and (max-width: 52.99em) {
	.menu {
		grid-area: auto;
		justify-content: center;
		padding: 0 2rem 0 2rem;
	}

	.intro__title {
		grid-area: auto;
		text-align: center;
	}

	.author {
		text-align: center;
		padding: var(--page-padding);
		color: #4000ea;
		--color-link: #4000ea;
		--color-link-hover: #4000ea;
	}

	.work-experience {
		padding: 2rem;
	}

	.timeline {
		flex-direction: column;
		padding: 1rem;
	}

	.portfolio-section {
		padding: 2rem;
	}

	.skills-section {
		padding: 2rem;
	}
	:root {
		--card_width: 130px;
		--card_border_radius: 16px;
		/* --row_increment: 10px; */
		--card_small: 10;
		--card_medium: 15;
		--card_large: 20;
		--row_increment: 0px;
		font-size: 13px;
	}
	.portfolio-container {
		padding: 0%;
		
	}
	section.work-experience {
		height: auto;
	}
	
	
}


@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
	}

	.vector {
		display: block;
	}

	main {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 28.05% 43.9% 28.05%;
		grid-template-rows: 25% 1fr 29.5% 8.5%;
		position: relative;
	}

	.title {
		grid-area: 1 / 2 / 2 / 3;
	}

	.menu {
		grid-area: 1 / 3 / 2 / 4;
		justify-content: flex-end;
	}

	.intro {
		grid-area: 1 / 1 / 3 / 2;
	}

	.intro__description {
		max-width: 20ch;
	}

	.gallery {
		border-radius: 25vw 25vw 0 0;
		width: 100%;
	}

	.slideshow {
		height: auto;
		grid-area: 2 / 2 / 5 / 3;
		grid-template-columns: 4rem 43.8vw 4rem;
		grid-template-rows: 38% 20% 42%;
		margin-left: -4rem;
		grid-template-areas: '... img title'
			'nav-prev img nav-next'
			'... img ...';
		justify-content: start;
	}

	.meta {
		margin: 3rem 0 0 1rem;
		text-align: left;
		justify-self: start;
	}

	.nav {
		align-self: start;
	}

	.nav__text {
		display: block;
	}

	.nav--prev {
		padding-right: 1.5rem;
	}

	.nav--next {
		padding-left: 1.5rem;
	}
	.timeline {
		flex-direction: row;
	}
	.skills-section {
		height: auto;
		padding: 0; 
	}
	.footer-section p {
		
		font-size: small;
	}
	
	
	
}
/* ojo cursor  */

#model-container {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 9999;
  }

.vector.vector--lines {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
  }
  
  .content-wrapper {
	position: relative;
	z-index: 1;
  }

  .skills-section {
	padding: 5rem 5rem;
	height: auto;
	
  }
  
  .skills-section__title {
    color: var(--color-text);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  
  .skills-grid {
	display: block;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 2rem;
  }
  
  .skill-item {
	flex-direction: revert;
    align-items: center;
    display: flex;
	justify-content: center;
  }
  
  .skill-item__logo {
	width: 100px;
	height: 80px;
	margin-bottom: 1rem;
	text-align: center;
  }
  
  .skill-item__logo img {
	
	height: 50%;
	object-fit: contain;
  }
  
  .skill-item__bar {
	background-color: var(--color-text);
    border-radius: 10px;
    width: 65%;
    height: 10px;
	margin-right: .5rem;
    overflow: hidden;
  }
  
  .skill-item__progress {
	height: 100%;
	background-color: var(--color-link-hover);
	transition: width 1s ease-in-out;
  }
  
  .skill-item__percentage {
	color: var(--color-text);
	font-size: 1rem;
	font-weight: bold;
  }

  .footer {
	background-color: var(--color-bg);
	color: var(--color-text);
	padding: 2rem;
  }
  
  .footer-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 2rem;
	margin-bottom: 2rem;
  }
  
  .footer-section {
	flex: 1;
  }
  
  .footer-section__title {
	font-size: 1.5rem;
	margin-bottom: 1rem;
  }
  
  .footer-section__text {
	font-size: 1rem;
	line-height: 1.5;
  }
  
  .footer-social-icons {
	list-style: none;
	padding: 0;
	display: flex;
	gap: 1rem;
  }
  
  .footer-social-icons a {
	color: var(--color-text);
	font-size: 1.5rem;
	transition: color 0.3s;
  }
  
  .footer-social-icons a:hover {
	color: var(--color-link-hover);
  }
  
  .footer-form {
	display: flex;
	gap: 1rem;
  }
  
  .footer-form input {
	flex: 1;
	padding: 0.5rem;
	border: none;
	border-radius: 5px;
  }
  
  .footer-form button {
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 5px;
	background-color: var(--color-link-hover);
	color: var(--color-bg);
	cursor: pointer;
	transition: background-color 0.3s;
  }
  
  .footer-form button:hover {
	background-color: var(--color-text);
  }
  
  .footer-bottom {
	text-align: left;
	font-size: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    .card {
        height: 300px;
    }
    
    
}