*, :after, :before {
  box-sizing: border-box;
}

:root {
  --color-bg: #4f00ff;
  --color-text: #000;
  --color-text-alt: #1d0460;
  --color-text-slide: #53dbf1;
  --color-link: #000;
  --color-link-hover: #f33ba5;
  --page-padding: 25px;
  font-size: 16px;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color: #000;
  background-color: var(--color-bg);
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  will-change: #53dbf1;
  font-family: termina, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  overflow-y: auto;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 3000;
  position: fixed;
}

.js .loading:before {
  background: var(--color-bg);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.js .loading:after {
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50%;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

a {
  color: var(--color-link);
  cursor: pointer;
  outline: none;
  text-decoration: underline;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
  text-decoration: none;
}

a:focus {
  background: #d3d3d3;
  outline: none;
}

a:focus:not(:focus-visible) {
  background: none;
}

a:focus-visible {
  background: none;
  outline: 2px solid red;
}

.unbutton {
  font: inherit;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.unbutton:focus {
  outline: none;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.vector {
  pointer-events: none;
  fill: none;
  stroke: #000;
  stroke-width: .5px;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.vector--circle {
  z-index: -1;
  width: 44%;
  top: -50%;
  left: 28%;
}

.intro {
  padding: var(--page-padding);
}

.intro__title {
  margin: 0 0 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.intro__description {
  color: var(--color-text-alt);
  line-height: 1.2;
}

.menu {
  padding: var(--page-padding);
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  display: flex;
}

.menu__item {
  color: var(--color-text);
  margin: .5rem;
  text-decoration: none;
  transition: color .3s;
}

.menu__item:hover {
  color: var(--color-link-hover);
}

.menu__item i {
  font-size: 1.5rem;
}

body.frame {
  margin: 0;
  overflow: auto;
}

.container_home {
  visibility: visible;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: block;
}

main {
  z-index: 1;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

section {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  display: list-item;
}

section.work-experience {
  height: 50vh;
}

@media screen and (width <= 52.99em) {
  main {
    height: auto;
    position: relative;
  }

  .scrollable-content {
    margin-top: 0;
  }

  section {
    min-height: auto;
  }
}

.author {
  padding-left: var(--page-padding);
  color: #000;
  --color-link: #000;
  --color-link-hover: #000;
  grid-area: 2 ​ / 1 / 4 / 2;
  align-self: center;
  font-size: .6rem;
}

.title {
  white-space: nowrap;
  padding: var(--page-padding);
  text-align: center;
  justify-self: center;
  margin: 0;
  font-size: clamp(1.75rem, 3vw, 15rem);
  line-height: 1;
}

.showreel {
  padding: var(--page-padding);
  color: var(--color-link-hover);
  grid-area: 4 / 3 / 5 / 4;
  place-self: center end;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.container_home {
  width: 100%;
  height: auto;
}

.showreel:before {
  content: "·";
  margin: 0 .25rem .25rem 0;
  font-size: 5rem;
  line-height: 0;
}

.showreel:hover:before {
  animation: .8s linear infinite alternate pulse;
}

@keyframes pulse {
  to {
    transform: scale3d(1.5, 1.5, 1);
  }
}

.slideshow {
  grid-template-columns: 60px minmax(200px, 70%) 60px;
  grid-template-areas: ". img ."
                       "nav-prev img nav-next"
                       ". img ."
                       "title title title";
  justify-content: center;
  height: 100vw;
  display: grid;
  position: relative;
}

.meta {
  text-align: center;
  grid-area: title;
  justify-self: center;
  width: max-content;
  margin: 1rem 0 0;
}

.no-js .meta {
  display: none;
}

.meta__heading {
  margin: 0;
  font-size: .75rem;
  font-weight: 600;
  display: block;
}

.meta__content {
  color: var(--color-text-slide);
  display: grid;
}

.meta__content-title {
  opacity: 0;
  will-change: opacity, transform;
  grid-area: 1 / 1 / 2 / 2;
}

.container {
  justify-content: center;
  align-items: stretch;
  gap: 1.25rem;
  width: 100%;
  max-width: 800px;
  height: 500px;
  transition: all .4s;
  display: flex;
}

.slides__img--current {
  pointer-events: auto;
  opacity: 1;
  z-index: 1000;
}

.work-experience {
  padding: var(--page-padding);
  color: var(--color-text);
  margin-top: 2rem;
}

.work-experience__title {
  color: var(--color-text);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.work-experience__years {
  font-weight: normal;
}

.timeline {
  position: unset;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.timeline__item {
  z-index: 1;
  color: #000;
  background-image: url("soporte_years.9c6c72d4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 180px;
  margin: .5rem;
  position: relative;
}

.timeline__year {
  color: var(--color-text);
  border-radius: 90px;
  padding: .8rem 3rem;
  font-size: .9rem;
  transition: background-color .3s, color .3s;
}

.timeline__year:before {
  content: attr(data-year);
  font-weight: bold;
  display: block;
}

.timeline__year:after {
  content: attr(data-info);
  color: var(--color-text-alt);
  margin-top: .25rem;
  font-size: .7rem;
  display: block;
}

.timeline__item--active .timeline__year, .timeline__item:hover .timeline__year {
  color: var(--color-bg);
}

#spline-container {
  width: 100%;
  height: 100vh;
  max-height: 1080px;
  position: relative;
  overflow: hidden;
}

#spline-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 575.98px) {
  #spline-container {
    max-height: 500px;
  }

  #spline-canvas {
    width: 100% !important;
    height: 100% !important;
  }

  #post {
    width: 80% !important;
  }
}

@media (width >= 576px) and (width <= 767.98px) {
  #spline-container {
    max-height: 856px;
  }

  #spline-canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

@media (width >= 768px) and (width <= 991.98px) {
  #spline-container {
    max-height: 400px;
  }
}

@media (width >= 992px) and (width <= 1199.98px) {
  #spline-container {
    max-height: 550px;
  }
}

@media (width >= 1200px) {
  #spline-container {
    max-height: 650px;
  }
}

@media (width >= 1400px) {
  #spline-container {
    max-height: 800px;
  }
}

:root {
  --card_width: 250px;
  --card_border_radius: 120px;
  --card_small: 10;
  --card_medium: 15;
  --card_large: 20;
}

.portfolio-section {
  padding: 2rem 8rem;
}

.portfolio-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 20px;
}

.pin_container {
  grid-template-columns: repeat(auto-fit, minmax(var(--card_width), 1fr));
  grid-auto-rows: var(--row_increment);
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  display: grid;
}

.gallery {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  display: grid;
}

.card {
  perspective: 1000px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 400px;
  position: relative;
}

.card img {
  object-fit: cover;
  clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
  width: 100%;
  height: 100%;
  transition: transform .5s;
}

.card:hover img {
  transform: rotateX(10deg)rotateY(-10deg)translateZ(50px);
}

.card .overlay {
  opacity: 0;
  clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
  background-color: #4f00ffc9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.card:hover .overlay {
  opacity: 1;
}

.card .overlay h3 {
  color: #00ffe9;
  margin-bottom: 10px;
  font-size: 24px;
}

.card .overlay p {
  color: #00ffe9;
  margin: 5px 0;
  font-size: 18px;
}

.popup {
  z-index: 999;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.popup:not([style*="display: flex"]) {
  pointer-events: none;
}

.popup-content {
  background-color: var(--color-bg);
  text-align: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.popup-content img {
  object-fit: contain;
  max-width: 100%;
  max-height: calc(90vh - 80px);
}

.close {
  color: #000;
  text-align: center;
  cursor: pointer;
  background-color: #53dbf1;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  transition: background-color .3s, transform .3s;
  position: absolute;
  top: 10px;
  right: 15px;
}

.close:hover {
  background-color: #3a00c2;
  transform: scale(1.1);
}

@media screen and (width <= 768px) {
  .popup-content {
    padding: 15px;
  }

  .popup-content img {
    max-height: calc(90vh - 60px);
  }

  .close {
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 25px;
    top: 5px;
    right: 10px;
  }
}

@media screen and (width <= 52.99em) {
  .menu {
    grid-area: auto;
    justify-content: center;
    padding: 0 2rem;
  }

  .intro__title {
    text-align: center;
    grid-area: auto;
  }

  .author {
    text-align: center;
    padding: var(--page-padding);
    color: #4000ea;
    --color-link: #4000ea;
    --color-link-hover: #4000ea;
  }

  .work-experience {
    padding: 2rem;
  }

  .timeline {
    flex-direction: column;
    padding: 1rem;
  }

  .portfolio-section, .skills-section {
    padding: 2rem;
  }

  :root {
    --card_width: 130px;
    --card_border_radius: 16px;
    --card_small: 10;
    --card_medium: 15;
    --card_large: 20;
    --row_increment: 0px;
    font-size: 13px;
  }

  .portfolio-container {
    padding: 0%;
  }

  section.work-experience {
    height: auto;
  }
}

@media screen and (width >= 53em) {
  body {
    overflow: hidden;
  }

  .vector {
    display: block;
  }

  main {
    grid-template-rows: 25% 1fr 29.5% 8.5%;
    grid-template-columns: 28.05% 43.9% 28.05%;
    width: 100%;
    height: 100%;
    display: grid;
    position: relative;
  }

  .title {
    grid-area: 1 / 2 / 2 / 3;
  }

  .menu {
    grid-area: 1 / 3 / 2 / 4;
    justify-content: flex-end;
  }

  .intro {
    grid-area: 1 / 1 / 3 / 2;
  }

  .intro__description {
    max-width: 20ch;
  }

  .gallery {
    border-radius: 25vw 25vw 0 0;
    width: 100%;
  }

  .slideshow {
    grid-template: ". img title" 38%
                   "nav-prev img nav-next" 20%
                   ". img ." 42%
                   / 4rem 43.8vw 4rem;
    grid-area: 2 / 2 / 5 / 3;
    justify-content: start;
    height: auto;
    margin-left: -4rem;
  }

  .meta {
    text-align: left;
    justify-self: start;
    margin: 3rem 0 0 1rem;
  }

  .nav {
    align-self: start;
  }

  .nav__text {
    display: block;
  }

  .nav--prev {
    padding-right: 1.5rem;
  }

  .nav--next {
    padding-left: 1.5rem;
  }

  .timeline {
    flex-direction: row;
  }

  .skills-section {
    height: auto;
    padding: 0;
  }

  .footer-section p {
    font-size: small;
  }
}

#model-container {
  pointer-events: none;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

.vector.vector--lines {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.content-wrapper {
  z-index: 1;
  position: relative;
}

.skills-section {
  height: auto;
  padding: 5rem;
}

.skills-section__title {
  color: var(--color-text);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.skills-grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  display: block;
}

.skill-item {
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  display: flex;
}

.skill-item__logo {
  text-align: center;
  width: 100px;
  height: 80px;
  margin-bottom: 1rem;
}

.skill-item__logo img {
  object-fit: contain;
  height: 50%;
}

.skill-item__bar {
  background-color: var(--color-text);
  border-radius: 10px;
  width: 65%;
  height: 10px;
  margin-right: .5rem;
  overflow: hidden;
}

.skill-item__progress {
  background-color: var(--color-link-hover);
  height: 100%;
  transition: width 1s ease-in-out;
}

.skill-item__percentage {
  color: var(--color-text);
  font-size: 1rem;
  font-weight: bold;
}

.footer {
  background-color: var(--color-bg);
  color: var(--color-text);
  padding: 2rem;
}

.footer-content {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.footer-section {
  flex: 1;
}

.footer-section__title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.footer-section__text {
  font-size: 1rem;
  line-height: 1.5;
}

.footer-social-icons {
  gap: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer-social-icons a {
  color: var(--color-text);
  font-size: 1.5rem;
  transition: color .3s;
}

.footer-social-icons a:hover {
  color: var(--color-link-hover);
}

.footer-form {
  gap: 1rem;
  display: flex;
}

.footer-form input {
  border: none;
  border-radius: 5px;
  flex: 1;
  padding: .5rem;
}

.footer-form button {
  background-color: var(--color-link-hover);
  color: var(--color-bg);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .5rem 1rem;
  transition: background-color .3s;
}

.footer-form button:hover {
  background-color: var(--color-text);
}

.footer-bottom {
  text-align: left;
  font-size: .5rem;
}

@media screen and (width <= 768px) {
  .card {
    height: 300px;
  }
}
/*# sourceMappingURL=index.4e30f555.css.map */
